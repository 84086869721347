<template>
  <div>
    <v-dialog v-model="dialog" v-if="this.mindate">
        <h1>MW予約分割</h1>

        <div class="d-flex w-50 mt-7">
        <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dates"
            multiple
            small-chips
            prepend-icon="mdi-calendar"
            persistent-hint
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        
        <v-date-picker
          v-model="dates"
          :min="this.mindate"
          :max="this.maxdate"
          multiple
          :show-current="this.mindate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="changeDate(),$refs.menu.save(dates.sort())"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      </div>
      <div v-for="(list, i) in listResers" :key="i">
        <div class="mt-8">
          <div class="d-flex justify-space-between">
            <div class="w-15">
              <h6>{{ list.facility }}</h6>
            </div>
            <div class="w-30 ml-5">
              <h6>{{ list.checkIn }}</h6>
            </div>
            <div class="w-30">
              <h6>{{ list.Checkout }}</h6>
            </div>
            <div class="w-15">
              <h6></h6>
            </div>
            <div class="w-45">
              <h6>{{ list.Accom }}</h6>
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="w-15 my-auto">
              <h4>{{ list.nameComy }}</h4>
            </div>
            <div class="w-30 my-auto ml-5">
              <h5>{{ list.timeCheck }}</h5>
            </div>
            <div class="w-30 my-auto">
              <h5>{{ list.timeOut }}</h5>
            </div>
            <div class="w-15 my-auto">
              <h4>{{ list.days }}</h4>
            </div>
            <div class="w-45">
              <v-combobox
               dense
               item-value="id"
               item-text="name"
               :items="clientMemberInfoNotificationRegistrationByPT"
               @change="multipleSelection($event,list, i)"
               >
               </v-combobox>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-end mt-5">
        <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
          {{ $t('common.close') }}
        </v-btn>
        <v-btn class="t-btn--prm" @click="save"> 分割実行 </v-btn>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { formatDate } from '@/constants/functions';
import gql from 'graphql-tag';

export default {
  name: 'mw-reservation-split',

  data() {
    return {
      dates: [],
      menu: false,
      dateItem: [],
      showData: [],
      // items: ['mit', 'coi', 'sunny', 'mit1', 'coi1', 'sunny1'],
      select: '',
      dateTime: '',
      date: new Date().toISOString().substr(0, 10),
      data: '',
      listResers: [],
      itemSelectedrights:[],
      itemSelectedfirstHalf:[],
      itemSelectedlatterHalf:[],
      countItem:0,
      countItemfirstHalf:0,
      countItemlatterHalf:0,
      checkReload:false,
      contractID:1,
      bookings:[],
      bookingGroupId:null,
      itemBooking:[],
      startDateSelected:null,
      endDateSelected:null,
      listDataResers:[],
      mindate:null,
      maxdate:null,
    };
  },
  props: {
    visible: Boolean,
  },
  mounted(){

  },
  watch: {
    getYearSelected() {
      this.bookings= []
      this.getAllData(this.getTypeSelected);
      this.checkReload = true
    },
    getTypeSelected() {
     this.bookings= []
     this.getAllData(this.getTypeSelected);
     this.checkReload = true
    }
  },
  created() {
    this.mwContractUsageList().then(() => {
      this.getAllData(this.getTypeSelected);
    })
    this.contractID = parseInt(this.$route.params.id);
    this.$apollo
        .query({
          query: gql`query (
            $id: Int!
          ) {
            contract(id: $id){
              clientId
            }
          }
          `,
          variables: {
            id: parseInt(this.$route.params.id)
          },
        })
        .then((data) => {
          this.getClientMemberInfoNotificationRegistrationByPT(data.data.contract.clientId);
        })
        .catch(async (error) => {
          console.log(error);
        });
    
  },
  computed: {
    ...mapGetters(['getmwContractUsageList','getYearSelected','getTypeSelected','clientMemberInfoNotificationRegistrationByPT']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions(['mwContractUsageList','getClientMemberInfoNotificationRegistrationByPT','actionsplitMwRight']),
    ...mapMutations(['setAlertSuccess', 'setStatusUpdateDepositMwBooking']),
    getAllData(type){
      this.startDateSelected = null;
      this.endDateSelected = null;
      this.itemSelectedrights = this.getmwContractUsageList.mwContractUsage.rights;
      if (this.itemSelectedrights){
        for (let i = 0; i < this.itemSelectedrights.length; i++) {
          const el = this.itemSelectedrights[i];
          this.itemSelectedfirstHalf = el.firstHalf
          this.itemSelectedlatterHalf = el.latterHalf
          this.countItemfirstHalf = 0;
          this.countItemlatterHalf = 0;
          if (el.year === this.getYearSelected) {
            if (el.splitInfo){
              const firstCheckInDate = el.splitInfo.firstCheckInDate;
              const lastCheckOutDate = el.splitInfo.lastCheckOutDate;
              this.dateTime = firstCheckInDate + ',' + lastCheckOutDate;
              this.dateTimebp = firstCheckInDate + ',' + lastCheckOutDate;
              this.bookingGroupId = el.splitInfo.bookingGroupId;
            } else {
              this.checkReload = false
              this.dateTime = '0000-00-00, 0000-00-00'
            }
            this.getCountItem(this.itemSelectedfirstHalf)
            this.countItemfirstHalf = this.countItem;
            this.getCountItem(this.itemSelectedlatterHalf)
            this.countItemlatterHalf = this.countItem;
            this.listResers = [];
            switch (type) {
              case 'All':
                this.getItemList(this.countItemfirstHalf + this.countItemlatterHalf,type);
                if (el.splitInfo) {
                  this.startDateSelected = el.splitInfo.firstCheckInDate;
                  this.endDateSelected = el.splitInfo.lastCheckOutDate;
                  this.bookingGroupId = el.splitInfo.bookingGroupId;
                }
                break;
              case 'firstHalf':
                this.getItemList(this.countItemfirstHalf,type);
                if (el.firstHalf.splitInfo) {
                  this.startDateSelected = el.firstHalf.splitInfo.firstCheckInDate;
                  this.endDateSelected = el.firstHalf.splitInfo.lastCheckOutDate;
                  this.bookingGroupId = el.firstHalf.splitInfo.bookingGroupId;
                }
                break;
              case 'latterHalf':
                this.getItemList(this.countItemlatterHalf,type);
                if (el.latterHalf.splitInfo) {
                  this.startDateSelected = el.latterHalf.splitInfo.firstCheckInDate;
                  this.endDateSelected = el.latterHalf.splitInfo.lastCheckOutDate;
                  this.bookingGroupId = el.latterHalf.splitInfo.bookingGroupId;
                }
                break;
              default:
                break;
            }
            if (this.startDateSelected && this.endDateSelected) {
              this.mindate = null;
              this.maxdate = null;
              this.mindate = this.getDayRange(this.startDateSelected,1)
              this.maxdate = this.getDayRange(this.endDateSelected,-1)
            }
          }
        }
      }
      
    },
    getDayRange(e , number){
      const arrDateCus = e.split('-')
      const day = parseInt(arrDateCus[2]) + number;
      arrDateCus[2] = day < 10 ? '0' + day : day
      return arrDateCus.join('-')
    },
    getCountItem(arr){
      this.countItem = 0;
      for (let i = 0; i < arr.rights.length; i++) {
         this.countItem++;
      }
    },
    getDataBookings(arr){
      const items = arr.newBookingGroup.bookings;
      const facilityName = arr.roomType.facility.name;
      for (let j = 0; j < items.length; j++) {
          const itemBookinhs = items[j];
          if (itemBookinhs.computed.status !== 'キャンセル') {
            const listItem = {
              idBooking: itemBookinhs.id,
              facility: '施設名',
              checkIn: '予約'+(j + 1)+'チェックイン',
              Checkout: '予約'+(j + 1)+'チェックアウト',
              Accom: '宿泊代表者',
              days: this.getDaysDiff(itemBookinhs.checkInDate,itemBookinhs.checkOutDate) +'泊',
              nameComy: facilityName,
              timeCheck: formatDate(itemBookinhs.checkInDate),
              timeOut: formatDate(itemBookinhs.checkOutDate),
              checkInDate: itemBookinhs.checkInDate
            }
            this.listDataResers.push(listItem)
          }
      }
    },
     getDaysDiff(start,end){
      const startDate = new Date(start).getDate();
      const endDate = new Date(end).getDate();
      return endDate - startDate;
    },
    getItemList(len,check){
      for (let i = 0; i < len; i++) {
          const j = 0
          if (i < this.countItemfirstHalf && check !== 'latterHalf') {
            this.getDataBookings(this.itemSelectedfirstHalf.rights[i])
          } else {
            this.j ++;
            this.getDataBookings(this.itemSelectedlatterHalf.rights[j])
          }
      }
    },
    save() {
      // we must delay the execution because @change event of the combobox will not be fired
      // if a new text is supplied instead of selecting an item from the list
      setTimeout(() => {
        const bookingArr = []
        if ( this.bookings.length > 0) {
          this.bookings.forEach(element => {
          bookingArr.push(element.data)
          });
          const data = {
            bookingGroupId: this.bookingGroupId,
            bookings: bookingArr,
            contractId: this.contractID
          };

          this.actionsplitMwRight(data).then((result) => {
            this.setStatusUpdateDepositMwBooking(true)
            this.setAlertSuccess('MW分割に成功しました。')
          });
        }
        this.closeDialog();
      }, 200);
    },
    closeDialog() {
      this.mindate = null;
      this.maxdate = null;
      this.$emit('close');
    },
    multipleSelection(el,item, idx) {
      const idBooking = this.bookingGroupId;
      this.itemBooking =[];
      if (el.ClientId) {
        this.itemBooking = {
          idx :idx,
          data: {
            representativeClientId: el.ClientId,
            checkInDate: item.checkInDate,
            representativeName: el.name
          }
        }
      } else if (typeof el === 'string') {
        this.itemBooking = {
          idx :idx,
          data: {
            checkInDate: item.checkInDate,
            representativeName: el
          }
        }
      } else {
        this.itemBooking = {
          idx :idx,
          data: {
            representativeSubMemberId: el.id,
            checkInDate: item.checkInDate,
            representativeName: el.name
          }
        }
      }
      this.AddnewItemBookings(idBooking,idx);
    },
    AddnewItemBookings(idBooking,idx){
      if (this.bookings.length === 0) {
        this.bookings.push({
          id: idBooking,
          data: this.itemBooking.data,
          idItemBooking: this.itemBooking.idx
        })
      } else {
        if (this.bookings.filter(x=>x.idItemBooking === idx).length > 0) {
          const el = this.bookings.filter(x=>x.idItemBooking === idx)[0];         
          el.data = this.itemBooking.data;
        } else {
          this.bookings.push({
            id: idBooking,
            data: this.itemBooking.data,
            idItemBooking: this.itemBooking.idx
          });
        }
      }
    },
    changeDate(){
      this.listResers = []
      const arrayDate = [this.startDateSelected,...this.dates.sort(),this.endDateSelected];
      if (this.dates.length === 0) {
        this.dates.push(arrayDate[0])
        this.dates.push(arrayDate[arrayDate.length - 1])
      }
      for (let j = 0; j < arrayDate.length - 1 ; j++) {
        const listItem = {
          facility: '施設名',
          checkIn: '予約'+(j + 1)+'チェックイン',
          Checkout: '予約'+(j + 1)+'チェックアウト',
          Accom: '宿泊代表者',
          days: this.getDaysDiff(arrayDate[j],arrayDate[j+1]) +'泊',
          nameComy: this.listDataResers.length > 0 ? this.listDataResers[0].nameComy : '',
          timeCheck: formatDate(arrayDate[j]),
          timeOut: formatDate(arrayDate[j + 1]),
          checkInDate : arrayDate[j],
        }
        this.listResers.push(listItem)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
h1 {
  color: #212121;
  font-size: 28px;
  font-weight: bold;
}
h4 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
h5 {
  color: #000 !important;
  font-size: 10px;
  font-weight: 500;
}
h6 {
  color: #000 !important;
  font-size: 10px;
  font-weight: 600;
}
.w-50 {
  width: 50%;
}
.w-15 {
  width: 15%;
}
.w-30 {
  width: 30%;
}
.w-45 {
  width: 45%;
}

.row + .row {
  margin-top: -30px !important;
}

.t-btn--prm {
  background: #1873d9 !important;
  color: #fff !important;
}
.mdi-calendar-month {
  color: #333 !important;
}
::v-deep {
  .v-dialog {
    width: 680px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 9px;
    .v-label {
      font-size: 10px;
      font-weight: 600;
      color: #000 !important;
    }
    .v-text-field__slot {
      input {
        color: #000000 !important;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .v-select__selection--comma {
      font-size: 14px !important;
      color: #000;
      font-weight: 500;
    }
  }
  .v-icon__svg {
    color: #333333 !important;
  }
  .v-data-table__wrapper,
  .v-card {
    background: #eeeeee !important;
  }

  .fa-calendar-check {
    height: 19px !important;
    width: 17px !important;
  }
}
</style>
