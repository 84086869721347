<template>
  <v-dialog
    v-model="dialog"
    max-width="554px"
    content-class="px-6 py-4 bg--white"
  >
    <h2 class="text--heading mb-3">デポジット</h2>
    <template v-if="depositData">
      <DepositItem
        v-if="multi || selected === 1"
        firstLabel="権利開始"
        secondLabel="権利終了"
        :firstDate="depositData.all.checkInDate"
        :secondDate="depositData.all.checkOutDate"
        buttonText="全日程デポジット"
        :className="selected === 1 && multi ? 'elevation-3 pl-3 rounded' : ''"
        @handleClick="handleUpdateDeposit(1)"
      />
      <DepositItem
        v-if="multi || selected === 2"
        firstLabel="前半開始"
        secondLabel="前半終了"
        :firstDate="firstHalfFrom"
        :secondDate="firstHalfTo"
        buttonText="前半デポジット"
        :className="selected === 2 && multi ? 'elevation-3 pl-3 rounded' : ''"
        @handleClick="handleUpdateDeposit(2)"
      />
      <DepositItem
        v-if="multi || selected === 3"
        firstLabel="後半開始"
        secondLabel="後半終了"
        :firstDate="latterHalfFrom"
        :secondDate="latterHalfTo"
        buttonText="後半デポジット"
        :className="selected === 3 && multi ? 'elevation-3 pl-3 rounded' : ''"
        @handleClick="handleUpdateDeposit(3)"
      />
    </template>
    <div class="text-right mt-4">
      <v-btn
        class="t-btn--red-dark mx-5 my-2"
        width="90"
        @click="$emit('close')"
      >
        <v-icon dense>mdi-close</v-icon>
        閉じる
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import DepositItem from '../DepositItem';
import { addDays } from '@/utils/dateUtil'

export default {
  name: 'DepositMwBookingDialog',
  components: {
    DepositItem,
  },
  props: {
    visible: Boolean,
    multi: Boolean,
    selected: Number,
    year: Number,
    id: [Number, String],
    depositData: Object
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit('close');
        }
      },
    },
    firstHalfFrom () {
      return this.depositData.firstHalf.checkInDate
    },
    firstHalfTo () {
      // this is the first 3 days from the from date.
      if (this.firstHalfFrom) {
        return addDays(this.firstHalfFrom, 3, { toISO: true })
      }
      return ''
    },
    latterHalfTo () {
      return this.depositData.latterHalf.checkOutDate
    },
    latterHalfFrom () {
      // this is the last 4 days from the last date.
      if (this.latterHalfTo) {
        return addDays(this.latterHalfTo, -4, { toISO: true })
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['updateDepositMwBooking']),
    async handleUpdateDeposit(type) {
      await this.updateDepositMwBooking({
        id: this.id,
        year: this.year,
        firstHalf: type === 1 || type === 2,
        latterHalf: type === 1 || type === 3,
      });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
h2{
  color: #212121 !important;
  font-weight: bold;
}
</style>
