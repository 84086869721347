<template>
  <div>
    <contract-detail :key="idxComponent">
      <!-- Title -->
      <template v-slot:title>{{ $route.params.type }}契約</template>
      <!-- End Title -->

      <!-- Content above 利用状況  -->
      <div
        class="mt-4 pa-5 general-info"
        style="margin-left: 25px; display: inline-flex; width: 97%"
      >
        <div style="text-align: center">
          <h2 class="text--title my-4">権利施設</h2>
          <label
            class="mw-custom-text-top"
            style="
              font: normal normal normal 16px/19px Noto Sans;
              letter-spacing: 0px;
              color: #424242;
              opacity: 1;
            "
            >{{ name }}</label
          >
        </div>
        <div class="ml-10" style="text-align: center">
          <h2 class="text--title my-4">週</h2>
          <label
            class="mw-custom-text-top"
            style="
              font: normal normal normal 16px/19px Noto Sans;
              letter-spacing: 0px;
              color: #424242;
              opacity: 1;
            "
            >{{ mwPwWeekNumber }}</label
          >
        </div>
        <div class="ml-10" style="text-align: center">
          <h2 class="text--title my-4">R</h2>
          <label
            class="mw-custom-text-top"
            style="
              font: normal normal normal 16px/19px Noto Sans;
              letter-spacing: 0px;
              color: #424242;
              opacity: 1;
            "
            >{{ mwRank }}</label
          >
        </div>

        <div class="mt-5" style="position: absolute; right: 20px">
          <v-btn small class="t-btn--red-dark ml-5" @click="$windowClose">
            <v-icon>mdi-close</v-icon>
            <span>&emsp;閉じる</span>
          </v-btn>
          <v-btn small class="t-btn--prm ml-3" :disabled="!checkUser"> 保存 </v-btn>
        </div>
      </div>
      <!-- End content above 利用状況  -->

      <!-- Content -->
      <!-- 利用状況 -->
      <div>
        <div class="mt-4 pa-5" style="margin-left: 25px">
          <h2
            class="text--title my-4"
            style="
              text-align: left;
              font: normal normal bold 20px/24px Noto Sans;
              letter-spacing: 0px;
              color: #004f8b;
              opacity: 1;
            "
          >
            利用状況
          </h2>
        </div>
        <div>
          <v-card
            v-for="(item, idx) in ArrYear"
            :key="idx + 'pw'"
            class="pa-5 mx-table mt-4"
            shaped
            tile
          >
            <div class="d-flex justify-space-between align-center mb-3">
              <div
                style="
                  text-align: left;
                  font-size: 18px;
                  letter-spacing: 0px;
                  color: #004f8b;
                  opacity: 1;
                "
              >
                {{ item }}年
              </div>
            </div>
            <div class="mb-3" style="display: inline-flex">
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn--prm"
                style="font-size: 14px"
                v-if="getCanSplit(item, facilitiesMWContract)"
                @click="getDataSplitMWByCanSplit(item, 0, true)"
              >
                分割
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn mw-custom-btn"
                style="font-size: 14px"
                v-else
                @click="getDataSplitMWByCanSplit(item, 0, false)"
              >
                分割
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                @click="showDepositDialog(item, 1)"
                v-if="getCanDeposit(item, facilitiesMWContract)"
              >
                デポジット
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn ml-3 mw-custom-btn"
                style="font-size: 14px"
                v-else
              >
                デポジット
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                @click="getAllData(item)"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                v-if="getCanExchange(item, facilitiesMWContract)"
              >
                MW交換予約
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn ml-3 mw-custom-btn"
                style="font-size: 14px"
                v-else
              >
                MW交換予約
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                @click="startRevokeForRCI(item, true, true)"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                v-if="getCanRevokeRCI(item, facilitiesMWContract)"
              >
                RCI償却
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn ml-3 mw-custom-btn"
                style="font-size: 14px"
                v-else
              >
                RCI償却
              </v-btn>
              <div class="ml-3">
                <div>
                  <label class="mw-custom-text">デポジット失効日</label>
                </div>
                <div style="text-align: center" class="mw-custom-text-b">
                  <label
                    v-text="getdepositExpireDate(item, facilitiesMWContract)"
                  ></label>
                </div>
              </div>
              <div class="ml-3">
                <div>
                  <label class="mw-custom-text">デポジット受付期限</label>
                </div>
                <div style="text-align: center" class="mw-custom-text-b">
                  <label
                    v-text="getdepositBy(item, facilitiesMWContract)"
                  ></label>
                </div>
              </div>
            </div>
            <div>
              <div class="mb-3" style="display: inline-flex">
                <div
                  style="
                    text-align: left;
                    font-size: 18px;
                    letter-spacing: 0px;
                    color: #004f8b;
                    opacity: 1;
                  "
                >
                  前半
                </div>
                <div class="ml-3" style="text-align: center">
                  <div>
                    <label class="mw-custom-text">状態</label>
                  </div>
                  <div
                    style="text-align: center; font-size: 14px; color: #444444"
                    class="mw-custom-text"
                  >
                    <label
                      v-text="getfirstHalfStatus(item, facilitiesMWContract)"
                    ></label>
                  </div>
                </div>

                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn--prm ml-3"
                  style="font-size: 14px"
                  v-if="getCanSplitfirstHalf(item, facilitiesMWContract)"
                  @click="getDataSplitMWByCanSplit(item, 1, true)"
                >
                  分割
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn mw-custom-btn ml-3"
                  style="font-size: 14px"
                  v-else
                  @click="getDataSplitMWByCanSplit(item, 1, false)"
                >
                  分割
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn--prm ml-3"
                  style="font-size: 14px"
                  @click="showDepositDialog(item, 2)"
                  v-if="getCanDepositfirstHalf(item, facilitiesMWContract)"
                >
                  デポジット
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn ml-3 mw-custom-btn"
                  style="font-size: 14px"
                  v-else
                >
                  デポジット
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  @click="getFirstData(item)"
                  small
                  class="t-btn--prm ml-3"
                  style="font-size: 14px"
                  v-if="getCanExchangefirstHalf(item, facilitiesMWContract)"
                >
                  MW交換予約
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn ml-3 mw-custom-btn"
                  style="font-size: 14px"
                  v-else
                >
                  MW交換予約
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  @click="startRevokeForRCI(item, true, false)"
                  small
                  class="t-btn--prm ml-3"
                  style="font-size: 14px"
                  v-if="getCanRevokeRCIfirstHalf(item, facilitiesMWContract)"
                >
                  RCI償却
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn ml-3 mw-custom-btn"
                  style="font-size: 14px"
                  v-else
                >
                  RCI償却
                </v-btn>

                <div class="ml-3">
                  <div>
                    <label class="mw-custom-text">デポジット失効日</label>
                  </div>
                  <div style="text-align: center" class="mw-custom-text-b">
                    <label
                      v-text="
                        getdepositExpireDatefirstHalf(
                          item,
                          facilitiesMWContract,
                        )
                      "
                    ></label>
                  </div>
                </div>
                <div class="ml-3">
                  <div>
                    <label class="mw-custom-text">デポジット受付期限</label>
                  </div>
                  <div style="text-align: center" class="mw-custom-text-b">
                    <label
                      v-text="getdepositByfirstHalf(item, facilitiesMWContract)"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <v-data-table
              :headers="headers"
              :items="filterYear(item, facilitiesMWContract)"
              class="elevation-1 custom-table mt-n4"
              :hide-default-footer="true"
              :item-class="itemRowBackground"
            >
              <template v-slot:[`item.stayConfirmedAt`]="{ item }">
                <div v-if="item.stayConfirmedAt == null">
                  <v-btn
                    :disabled="!checkUser"
                    v-text="UseUnconfirmed"
                    v-model="item.UsageConfirmation"
                    class="pw-custom"
                    style="
                      background: #f5511e 0% 0% no-repeat padding-box;
                      border-radius: 6px;
                      opacity: 1;
                      text-align: center;
                    "
                    @click="
                      item.UsageConfirmation = 'false';
                      ChangeUseUnconfirmed(item.bookingsID, 0);
                    "
                  >
                    <span>&emsp;閉じる</span>
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    :disabled="!checkUser"
                    v-text="labelUsageConfirmation"
                    class="pw-custom"
                    style="
                      background: #ffc3b1 0% 0% no-repeat padding-box;
                      border-radius: 6px;
                      opacity: 1;
                      text-align: center;
                    "
                    @click="ChangeUseUnconfirmed(item.bookingsID, 1)"
                  >
                    <span>&emsp;閉じる</span>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.btnEdit`]="{ item }">
                <v-btn
                  icon
                  style="
                    background-color: #13ace0 !important;
                    width: 28px;
                    height: 28px;
                  "
                  @click="getLinkPMS(item.bookingsID, item.newBookingGroupID)"
                >
                  <v-icon
                    style="color: white !important"
                    color="#FFFFFF"
                    size="18"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>

            <div class="mb-3"></div>
            <div>
              <div class="mb-3" style="display: inline-flex">
                <div
                  style="
                    text-align: left;
                    font-size: 18px;
                    letter-spacing: 0px;
                    color: #004f8b;
                    opacity: 1;
                  "
                >
                  後半
                </div>
                <div class="ml-3" style="text-align: center">
                  <div>
                    <label class="mw-custom-text">状態</label>
                  </div>
                  <div
                    style="text-align: center; font-size: 14px; color: #444444"
                    class="mw-custom-text"
                  >
                    <label
                      v-text="
                        getfirstHalfStatus(item, facilitiesMWContractlatterHalf)
                      "
                    ></label>
                  </div>
                </div>

                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn--prm ml-3"
                  style="font-size: 14px"
                  v-if="
                    getCanSplitfirstHalf(item, facilitiesMWContractlatterHalf)
                  "
                  @click="getDataSplitMWByCanSplit(item, 2, true)"
                >
                  分割
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn mw-custom-btn ml-3"
                  style="font-size: 14px"
                  v-else
                  @click="getDataSplitMWByCanSplit(item, 2, false)"
                >
                  分割
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn--prm ml-3"
                  style="font-size: 14px"
                  @click="showDepositDialog(item, 3)"
                  v-if="
                    getCanDepositfirstHalf(item, facilitiesMWContractlatterHalf)
                  "
                >
                  デポジット
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn ml-3 mw-custom-btn"
                  style="font-size: 14px"
                  v-else
                >
                  デポジット
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  @click="getLatterData(item)"
                  small
                  class="t-btn--prm ml-3"
                  style="font-size: 14px"
                  v-if="
                    getCanExchangefirstHalf(
                      item,
                      facilitiesMWContractlatterHalf,
                    )
                  "
                >
                  MW交換予約
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn ml-3 mw-custom-btn"
                  style="font-size: 14px"
                  v-else
                >
                  MW交換予約
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  @click="startRevokeForRCI(item, false, true)"
                  small
                  class="t-btn--prm ml-3"
                  style="font-size: 14px"
                  v-if="getCanRevokeRCIfirstHalf(item, facilitiesMWContractlatterHalf)"
                >
                  RCI償却
                </v-btn>
                <v-btn
                  :disabled="!checkUser"
                  small
                  class="t-btn ml-3 mw-custom-btn"
                  style="font-size: 14px"
                  v-else
                >
                  RCI償却
                </v-btn>

                <div class="ml-3">
                  <div>
                    <label class="mw-custom-text">デポジット失効日</label>
                  </div>
                  <div style="text-align: center" class="mw-custom-text-b">
                    <label
                      v-text="
                        getdepositExpireDatefirstHalf(
                          item,
                          facilitiesMWContractlatterHalf,
                        )
                      "
                    ></label>
                  </div>
                </div>
                <div class="ml-3">
                  <div>
                    <label class="mw-custom-text">デポジット受付期限</label>
                  </div>
                  <div style="text-align: center" class="mw-custom-text-b">
                    <label
                      v-text="
                        getdepositByfirstHalf(
                          item,
                          facilitiesMWContractlatterHalf,
                        )
                      "
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <v-data-table
              :headers="headers"
              :items="filterYear(item, facilitiesMWContractlatterHalf)"
              class="elevation-1 custom-table mt-n4"
              :hide-default-footer="true"
              :item-class="itemRowBackground"
            >
              <template v-slot:[`item.stayConfirmedAt`]="{ item }">
                <div v-if="item.stayConfirmedAt == null">
                  <v-btn
                    :disabled="!checkUser"
                    v-text="UseUnconfirmed"
                    v-model="item.UsageConfirmation"
                    class="pw-custom"
                    style="
                      background: #f5511e 0% 0% no-repeat padding-box;
                      border-radius: 6px;
                      opacity: 1;
                      text-align: center;
                    "
                    @click="ChangeUseUnconfirmed(item.bookingsID, 0)"
                  >
                    <span>&emsp;閉じる</span>
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    :disabled="!checkUser"
                    v-text="labelUsageConfirmation"
                    class="pw-custom"
                    style="
                      background: #ffc3b1 0% 0% no-repeat padding-box;
                      border-radius: 6px;
                      opacity: 1;
                      text-align: center;
                    "
                    @click="ChangeUseUnconfirmed(item.bookingsID, 1)"
                  >
                    <span>&emsp;閉じる</span>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.btnEdit`]="{ item }">
                <v-btn
                  icon
                  style="
                    background-color: #13ace0 !important;
                    width: 28px;
                    height: 28px;
                  "
                  @click="getLinkPMS(item.bookingsID, item.newBookingGroupID)"
                >
                  <v-icon
                    style="color: white !important"
                    color="#FFFFFF"
                    size="18"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
      <!-- end 利用状況 -->

      <!-- 期限切れ契約 -->
      <div>
        <div class="mt-4 pa-5" style="margin-left: 25px">
          <h2
            class="text--title my-4"
            style="
              text-align: left;
              font: normal normal bold 20px/24px Noto Sans;
              letter-spacing: 0px;
              color: #004f8b;
              opacity: 1;
            "
          >
            期限切れ契約
          </h2>
        </div>

        <v-card
          v-for="(item, idx) in ArrYearexpired"
          :key="idx + 'pwexpired'"
          class="pa-5 mx-table mt-4 mb-10"
          shaped
          tile
        >
          <div class="d-flex justify-space-between align-center mb-3">
            <div
              style="
                text-align: left;
                font-size: 18px;
                letter-spacing: 0px;
                color: #004f8b;
                opacity: 1;
              "
            >
              {{ item }}年
            </div>
          </div>

          <div class="mb-3" style="display: inline-flex">
            <v-btn
              :disabled="!checkUser"
              small
              class="t-btn--prm"
              style="font-size: 14px"
              v-if="getCanSplit(item, facilitiesMWContract)"
              @click="getDataSplitMWByCanSplit(item, 0, true)"
            >
              分割
            </v-btn>
            <v-btn
              :disabled="!checkUser"
              small
              class="t-btn mw-custom-btn"
              style="font-size: 14px"
              v-else
              @click="getDataSplitMWByCanSplit(item, 0, false)"
            >
              分割
            </v-btn>
            <v-btn
              :disabled="!checkUser"
              small
              class="t-btn--prm ml-3"
              style="font-size: 14px"
              @click="showDepositDialog(item, 1)"
              v-if="getCanDeposit(item, facilitiesMWContract)"
            >
              デポジット
            </v-btn>
            <v-btn
              :disabled="!checkUser"
              small
              class="t-btn ml-3 mw-custom-btn"
              style="font-size: 14px"
              v-else
            >
              デポジット
            </v-btn>
            <v-btn
              @click="getAllData(item)"
              :disabled="!checkUser"
              small
              class="t-btn--prm ml-3"
              style="font-size: 14px"
              v-if="getCanExchange(item, facilitiesMWContract)"
            >
              MW交換予約
            </v-btn>
            <v-btn
              :disabled="!checkUser"
              small
              class="t-btn ml-3 mw-custom-btn"
              style="font-size: 14px"
              v-else
            >
              MW交換予約
            </v-btn>
            <v-btn
              :disabled="!checkUser"
              @click="startRevokeForRCI(item, true, true)"
              small
              class="t-btn--prm ml-3"
              style="font-size: 14px"
              v-if="getCanRevokeRCI(item, facilitiesMWContract)"
            >
              RCI償却
            </v-btn>
            <v-btn
              :disabled="!checkUser"
              small
              class="t-btn ml-3 mw-custom-btn"
              style="font-size: 14px"
              v-else
            >
              RCI償却
            </v-btn>
            <div class="ml-3">
              <div>
                <label class="mw-custom-text">デポジット失効日</label>
              </div>
              <div style="text-align: center" class="mw-custom-text-b">
                <label
                  v-text="getdepositExpireDate(item, facilitiesMWContract)"
                ></label>
              </div>
            </div>
            <div class="ml-3">
              <div>
                <label class="mw-custom-text">デポジット受付期限</label>
              </div>
              <div style="text-align: center" class="mw-custom-text-b">
                <label
                  v-text="getdepositBy(item, facilitiesMWContract)"
                ></label>
              </div>
            </div>
          </div>

          <!-- table first half  -->
          <div>
            <div class="mb-3" style="display: inline-flex">
              <div class="title">前半</div>
              <div class="ml-3" style="text-align: center">
                <div>
                  <label class="mw-custom-text">状態</label>
                </div>
                <div
                  style="text-align: center; font-size: 14px; color: #444444"
                  class="mw-custom-text"
                >
                  <label
                    v-text="getfirstHalfStatus(item, facilitiesMWContract)"
                  ></label>
                </div>
              </div>

              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                v-if="getCanSplitfirstHalf(item, facilitiesMWContract)"
                @click="getDataSplitMWByCanSplit(item, 1, true)"
              >
                分割
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn mw-custom-btn ml-3"
                style="font-size: 14px"
                v-else
                @click="getDataSplitMWByCanSplit(item, 1, false)"
              >
                分割
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                @click="showDepositDialog(item, 2)"
                v-if="getCanDepositfirstHalf(item, facilitiesMWContract)"
              >
                デポジット
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn ml-3 mw-custom-btn"
                style="font-size: 14px"
                v-else
              >
                デポジット
              </v-btn>
              <v-btn
                @click="getFirstData(item)"
                :disabled="!checkUser"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                v-if="getCanExchangefirstHalf(item, facilitiesMWContract)"
              >
                MW交換予約
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn ml-3 mw-custom-btn"
                style="font-size: 14px"
                v-else
              >
                MW交換予約
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                @click="startRevokeForRCI(item, true, false)"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                v-if="getCanRevokeRCIfirstHalf(item, facilitiesMWContract)"
              >
                RCI償却
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn ml-3 mw-custom-btn"
                style="font-size: 14px"
                v-else
              >
                RCI償却
              </v-btn>

              <div class="ml-3">
                <div>
                  <label class="mw-custom-text">デポジット失効日</label>
                </div>
                <div style="text-align: center" class="mw-custom-text-b">
                  <label
                    v-text="
                      getdepositExpireDatefirstHalf(item, facilitiesMWContract)
                    "
                  ></label>
                </div>
              </div>
              <div class="ml-3">
                <div>
                  <label class="mw-custom-text">デポジット受付期限</label>
                </div>
                <div style="text-align: center" class="mw-custom-text-b">
                  <label
                    v-text="getdepositByfirstHalf(item, facilitiesMWContract)"
                  ></label>
                </div>
              </div>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="filterYear(item, facilitiesMWContract)"
            class="elevation-1 custom-table mt-n4"
            :hide-default-footer="true"
            :item-class="itemRowBackground"
          >
            <template v-slot:[`item.stayConfirmedAt`]="{ item }">
              <div v-if="item.stayConfirmedAt == null">
                <v-btn
                  :disabled="!checkUser"
                  v-text="UseUnconfirmed"
                  v-model="item.UsageConfirmation"
                  class="pw-custom"
                  style="
                    background: #f5511e 0% 0% no-repeat padding-box;
                    border-radius: 6px;
                    opacity: 1;
                    text-align: center;
                  "
                  @click="
                    item.UsageConfirmation = 'false';
                    ChangeUseUnconfirmed(item.bookingsID, 0);
                  "
                >
                  <span>&emsp;閉じる</span>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  :disabled="!checkUser"
                  v-text="labelUsageConfirmation"
                  class="pw-custom"
                  style="
                    background: #ffc3b1 0% 0% no-repeat padding-box;
                    border-radius: 6px;
                    opacity: 1;
                    text-align: center;
                  "
                  @click="ChangeUseUnconfirmed(item.bookingsID, 1)"
                >
                  <span>&emsp;閉じる</span>
                </v-btn>
              </div>
            </template>
            <template v-slot:[`item.btnEdit`]="{ item }">
              <v-btn
                icon
                style="
                  background-color: #13ace0 !important;
                  width: 28px;
                  height: 28px;
                "
                @click="getLinkPMS(item.bookingsID, item.newBookingGroupID)"
              >
                <v-icon
                  style="color: white !important"
                  color="#FFFFFF"
                  size="18"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>

          <!-- table latter half  -->
          <div class="my-4">
            <div class="mb-3" style="display: inline-flex">
              <div class="title">後半</div>
              <div class="ml-3" style="text-align: center">
                <div>
                  <label class="mw-custom-text">状態</label>
                </div>
                <div
                  style="text-align: center; font-size: 14px; color: #444444"
                  class="mw-custom-text"
                >
                  <label
                    v-text="
                      getfirstHalfStatus(item, facilitiesMWContractlatterHalf)
                    "
                  ></label>
                </div>
              </div>

              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                v-if="
                  getCanSplitfirstHalf(item, facilitiesMWContractlatterHalf)
                "
                @click="getDataSplitMWByCanSplit(item, 2, true)"
              >
                分割
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn mw-custom-btn ml-3"
                style="font-size: 14px"
                v-else
                @click="getDataSplitMWByCanSplit(item, 2, false)"
              >
                分割
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                @click="showDepositDialog(item, 3)"
                v-if="
                  getCanDepositfirstHalf(item, facilitiesMWContractlatterHalf)
                "
              >
                デポジット
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn ml-3 mw-custom-btn"
                style="font-size: 14px"
                v-else
              >
                デポジット
              </v-btn>
              <v-btn
                @click="getLatterData(item)"
                :disabled="!checkUser"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                v-if="
                  getCanExchangefirstHalf(item, facilitiesMWContractlatterHalf)
                "
              >
                MW交換予約
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn ml-3 mw-custom-btn"
                style="font-size: 14px"
                v-else
              >
                MW交換予約
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                @click="startRevokeForRCI(item, false, true)"
                small
                class="t-btn--prm ml-3"
                style="font-size: 14px"
                v-if="getCanRevokeRCIfirstHalf(item, facilitiesMWContractlatterHalf)"
              >
                RCI償却
              </v-btn>
              <v-btn
                :disabled="!checkUser"
                small
                class="t-btn ml-3 mw-custom-btn"
                style="font-size: 14px"
                v-else
              >
                RCI償却
              </v-btn>

              <div class="ml-3">
                <div>
                  <label class="mw-custom-text">デポジット失効日</label>
                </div>
                <div style="text-align: center" class="mw-custom-text-b">
                  <label
                    v-text="
                      getdepositExpireDatefirstHalf(
                        item,
                        facilitiesMWContractlatterHalf,
                      )
                    "
                  ></label>
                </div>
              </div>
              <div class="ml-3">
                <div>
                  <label class="mw-custom-text">デポジット受付期限</label>
                </div>
                <div style="text-align: center" class="mw-custom-text-b">
                  <label
                    v-text="
                      getdepositByfirstHalf(
                        item,
                        facilitiesMWContractlatterHalf,
                      )
                    "
                  ></label>
                </div>
              </div>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="filterYear(item, facilitiesMWContractlatterHalf)"
            class="elevation-1 custom-table mt-n4"
            :hide-default-footer="true"
            :item-class="itemRowBackground"
          >
            <template v-slot:[`item.stayConfirmedAt`]="{ item }">
              <div v-if="item.stayConfirmedAt == null">
                <v-btn
                  :disabled="!checkUser"
                  v-text="UseUnconfirmed"
                  v-model="item.UsageConfirmation"
                  class="pw-custom"
                  style="
                    background: #f5511e 0% 0% no-repeat padding-box;
                    border-radius: 6px;
                    opacity: 1;
                    text-align: center;
                  "
                  @click="ChangeUseUnconfirmed(item.bookingsID, 0)"
                >
                  <span>&emsp;閉じる</span>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  :disabled="!checkUser"
                  v-text="labelUsageConfirmation"
                  class="pw-custom"
                  style="
                    background: #ffc3b1 0% 0% no-repeat padding-box;
                    border-radius: 6px;
                    opacity: 1;
                    text-align: center;
                  "
                  @click="ChangeUseUnconfirmed(item.bookingsID, 1)"
                >
                  <span>&emsp;閉じる</span>
                </v-btn>
              </div>
            </template>
            <template v-slot:[`item.btnEdit`]="{ item }">
              <v-btn
                icon
                style="
                  background-color: #13ace0 !important;
                  width: 28px;
                  height: 28px;
                "
                @click="getLinkPMS(item.bookingsID, item.newBookingGroupID)"
              >
                <v-icon
                  style="color: white !important"
                  color="#FFFFFF"
                  size="18"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <!-- end 期限切れ契約 -->
      <!-- End Content  -->

      <!-- From dialog -->
      <ContractDialog
        :visible="dialog === 1"
        @close="close"
        className="custom-dialog"
        :width="180"
      >
        <template v-slot:content v-if="statusSelect">
          <div class="d-flex align-center">
            <v-select
              v-model="selectedYes"
              :items="listSelected"
              item-text="text"
              item-value="id"
              style="height: 40px"
              @change="changeSelected(selectedYes)"
            />
          </div>
        </template>
      </ContractDialog>

      <MWReservationSplit
        :statusShowAll="statusShowAll"
        :visible="dialog === 2"
        @close="close"
        :key="indexCom"
      ></MWReservationSplit>

      <DepositMwBookingDialog
        :selected="depositSelected"
        :visible="isOpenDepositDialog"
        :year="currentYear"
        :id="depositId"
        :depositData="depositData"
        @close="close"
      />
      <!-- End From dialog -->
    </contract-detail>
  </div>
</template>

<script>
import ContractDialog from '@/components/dialog/ContractDialog';
import contractDetail from '../../ContractDetail.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import router from '@/router';
import MWReservationSplit from './MWReservationSplit.vue';
import DepositMwBookingDialog from '../../../../../components/dialog/DepositMwBookingDialog.vue';
import { LINK_PMS } from '@/constants/linkPMS.js';
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { calculateStayDays } from '@/utils/dateUtil'

const STATUS_INDETERMINATE = '-'

export default {
  name: 'MWUsageStatus',
  data() {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      idxComponent: 0,
      checkClient: false,
      bookingId: null,
      isUdo: false,
      depositData: null,
      depositId: this.$route.params.id,
      currentYear: 2021,
      depositSelected: 1,
      indexCom: 0,
      isOpenDepositDialog: false,
      itemSelected: {},
      statusShowAll: 0,
      statusSelect: true,
      labelUsageConfirmation: '利用確認済み',
      UseUnconfirmed: '利用未確認',
      facilitiesMWContract: [],
      facilitiesMWContractlatterHalf: [],
      contractID: null,
      contractApi: [],
      pwContractUsageApi: [],
      bookingGroup: [],
      validity: [],
      ArrYear: [],
      ArrYearexpired: [],
      visible: -1,
      dialog: -1,
      selectedYes: 1,
      headers: [
        { text: '履歴', value: 'mwstatus' },
        { text: '日数', value: 'days', width: '15px' },
        { text: '施設', value: 'name' },
        { text: '部屋タイプ', value: 'roomTypeName' },
        { text: '予約詳細', value: 'btnEdit', width: '15px' }, //  button edit
        { text: '予約ID', value: 'bookingsID', width: '15px' },
        { text: '予約ステータス', value: 'computedStatus' },
        { text: '利用確認', value: 'stayConfirmedAt' }, // btn check
        { text: 'チェックイン日', value: 'firstCheckInDate' },
        { text: '最終日', value: 'lastCheckOutDate' },
        { text: '泊数', value: 'NumberOfNights', width: '15px' },
        { text: '現金換算額', value: 'CashConversionAmount' }, // can confirm
        { text: '交換成立日', value: 'exchangedAt' },
        { text: 'デポジット日', value: 'depositAt' },
        { text: '作成日', value: 'createdAt' },
      ],
      mwContractUsage: [],
      rights: [],
      // table
      mwstatus: null,
      days: null,
      year: null,
      name: null,
      bookingsID: null,
      computedStatus: null,
      firstCheckInDate: null,
      lastCheckOutDate: null,
      NumberOfNights: null,
      CashConversionAmount: '¥00,000',
      exchangedAt: null,
      depositAt: null,
      createdAt: null,
      mwPwWeekNumber: null,
      mwRank: null,
      depositExpireDate: null,
      depositBy: null,
      depositExpireDatefirstHalf: null,
      depositByfirstHalf: null,
      firstHalfStatus: null,
      // end
      listSelected: [
        { text: '利用未確認', id: 1 },
        { text: '利用確認済み', id: 2 },
      ],
      totalYears: 0,
      mw_item: [],
      firstHalf: [],
      arrcomputedStatus: [],
      stayConfirmedAt: null,
      startYear: null,
      dateNow: new Date().getFullYear(),
      dataAll: [],
      datagroupRoomFilter: [],
      arrayMaxDateByYear: [],
    };
  },
  watch: {
    getStatusUpdateDepositMwBooking () {
      this.idxComponent += 1
      this.isOpenDepositDialog = false
      this.reload()
    },

    getDialogSuccess() {
      this.reload()
      this.contractID = parseInt(router.currentRoute.params.id);
    },
  },
  mounted() {
    this.mwContractUsageList().then(() => {
      this.initFacilities();
      this.checkClient = true;
    });
    this.contractID = parseInt(router.currentRoute.params.id);
  },
  computed: {
    ...mapGetters(['getmwContractUsageList', 'getDialogSuccess', 'getStatusUpdateDepositMwBooking']),
  },

  methods: {
    ...mapActions(['mwContractUsageList', 'UpdatepwContractUsageList']),
    ...mapMutations([
      'setAlertSuccess',
      'setDialogSuccess',
      'setYearSelected',
      'setTypeSelected',
    ]),
    close(){
      this.dialog = -1
      this.isOpenDepositDialog = false
    },
    reload () {
      this.mwContractUsageList().then((data) => {
        this.facilitiesMWContract = [];
        this.facilitiesMWContractlatterHalf = [];
        this.initFacilities();
      });
    },
    itemRowBackground(item) {
      if (item.only === 2) {
        return 'style-border-btm-none';
      } else {
        return item.mwstatus === null ? 'style-border-none' : '';
      }
    },
    async showDepositDialog(year, type) {
      await this.handleDepositDialog(year, type);
      this.isOpenDepositDialog = true;
    },
    handleDepositDialog(year, type) {
      this.currentYear = year;
      this.depositSelected = type;
      const data = this.mwContractUsage.rights.find(
        (item) => item.year === year,
      );
      if (data) {
        this.depositData = {
          all: {
            depositBy: data.weekInfo.fromDate,
            depositExpireDate: data.weekInfo.toDate,
            checkInDate: data.splitInfo?.firstCheckInDate,
            checkOutDate: data.splitInfo?.lastCheckOutDate,
          },
          firstHalf: {
            depositBy: data.weekInfo.firstHalfFromDate,
            depositExpireDate: data.weekInfo.firstHalfToDate,
            checkInDate: data.firstHalf.splitInfo?.firstCheckInDate,
            checkOutDate: data.firstHalf.splitInfo?.lastCheckOutDate,
          },
          latterHalf: {
            depositBy: data.weekInfo.latterHalfFromDate,
            depositExpireDate: data.weekInfo.latterHalfToDate,
            checkInDate: data.latterHalf.splitInfo?.firstCheckInDate,
            checkOutDate: data.latterHalf.splitInfo?.lastCheckOutDate,
          },
        };
      }
    },
    getFirstData(year) {
      const data = this.mwContractUsage.rights.find(
        (item) => item.year === year,
      );
      const client = this.mwContractUsage.client;

      const dataExChange = {
        deposit: data.firstHalf.actions.canDeposit,
        type: 1,
        dataUpdate: data.firstHalf,
        mwRank: this.mwRank,
        client: client,
        contractId: this.contractID,
        year: year,
        firstHalf: true,
        latterHalf: false,
        checkInDate: data.firstHalf.splitInfo
          ? data.firstHalf.splitInfo.firstCheckInDate
          : null,
        checkOutDate: data.firstHalf.splitInfo
          ? data.firstHalf.splitInfo.lastCheckOutDate
          : null,
        rights: data,
      };

      localStorage.setItem(
        'setDataToExchangeReservation',
        JSON.stringify(dataExChange),
      );
      window.open('/contract/create-exchange-reservation?type=MW', '_blank');
    },

    getLatterData(year) {
      const data = this.mwContractUsage.rights.find(
        (item) => item.year === year,
      );
      const client = this.mwContractUsage.client;

      const dataExChange = {
        deposit: data.latterHalf.actions.canDeposit,
        type: 2,
        dataUpdate: data.latterHalf,
        mwRank: this.mwRank,
        client: client,
        contractId: this.contractID,
        year: year,
        firstHalf: false,
        latterHalf: true,
        checkInDate: data.latterHalf.splitInfo
          ? data.latterHalf.splitInfo.firstCheckInDate
          : null,
        checkOutDate: data.latterHalf.splitInfo
          ? data.latterHalf.splitInfo.lastCheckOutDate
          : null,
        rights: data,
      };

      localStorage.setItem(
        'setDataToExchangeReservation',
        JSON.stringify(dataExChange),
      );
      window.open('/contract/create-exchange-reservation?type=MW', '_blank');
    },

    getAllData(year) {
      const data = this.mwContractUsage.rights.find(
        (item) => item.year === year,
      );
      const client = this.mwContractUsage.client;

      const dataExChange = {
        deposit: data.actions.canDeposit,
        type: 0,
        dataUpdate: data.firstHalf,
        mwRank: this.mwRank,
        client: client,
        contractId: this.contractID,
        year: year,
        firstHalf: true,
        latterHalf: true,
        checkInDate: data.splitInfo ? data.splitInfo.firstCheckInDate : null,
        checkOutDate: data.splitInfo ? data.splitInfo.lastCheckOutDate : null,
        rights: data,
      };

      localStorage.setItem(
        'setDataToExchangeReservation',
        JSON.stringify(dataExChange),
      );
      window.open('/contract/create-exchange-reservation?type=MW', '_blank');
    },

    filterYear(item, arr) {
      // const arrfilter = arr.filter((el) => el.year === item && el.computedStatus !== 'キャンセル');
      const arrfilter = arr.filter((el) => el.year === item);
      const groupRoomFilter = arrfilter.reduce((r, a) => {
        r[a.newBookingGroupID] = [...(r[a.newBookingGroupID] || []), a];
        return r;
      }, []);
      this.datagroupRoomFilter = Object.keys(groupRoomFilter)
        .map((key) => [Number(key), groupRoomFilter[key]])
        .forEach((element) => {
          element.forEach((val) => {
            if (val) {
              for (let index = 1; index < val.length; index++) {
                arrfilter[index].mwstatus = null;
                arrfilter[index].days = null;
                arrfilter[index].name = null;
                arrfilter[index].roomTypeName = null;
                arrfilter[index].exchangedAt = null;
                arrfilter[index].depositAt = null;
                arrfilter[index].CashConversionAmount = null;
                arrfilter[index].createdAt = null;
              }
              if (val.length === 1) {
                arrfilter[0].only = 1;
              } else {
                arrfilter[0].only = 2;
              }
            }
          });
        });
      return arrfilter.slice().sort((a, b) => b.bookingsID - a.bookingsID);
    },

    getCanSplit(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.canSplit ?? false;
    },
    getCanDeposit(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.canDeposit ?? false;
    },
    getCanExchange(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.canExchange ?? false;
    },
    getCanRevokeRCI(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.canRevokeForRCI ?? false;
    },
    getfirstHalfStatus(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.firstHalfStatus ?? STATUS_INDETERMINATE;
    },
    getCanSplitfirstHalf(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.canSplitfirstHalf ?? false;
    },
    getCanDepositfirstHalf(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.canDepositfirstHalf ?? false;
    },
    getCanExchangefirstHalf(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.canExchangefirstHalf ?? false;
    },
    getCanRevokeRCIfirstHalf(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.canRevokeForRCIfirstHalf ?? false;
    },
    getdepositExpireDate(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.depositExpireDate;
    },
    getdepositBy(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.depositBy;
    },
    getdepositExpireDatefirstHalf(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.depositExpireDatefirstHalf;
    },
    getdepositByfirstHalf(item, arr) {
      const filterdata = arr.filter((el) => el.year === item)[0];
      return filterdata?.depositByfirstHalf;
    },
    initFacilities() {
      this.getData('firstHalf');
      this.getData('latterHalf');
      this.getArrayYear();
    },
    getDatafacilitiesMWContractByYear(val) {},
    getArrayYear() {
      this.ArrYear = [];
      this.ArrYearexpired = [];
      this.mwContractUsage.rights.forEach(element => {
        const data = this.arrayMaxDateByYear.find(v=>v.year === element.year);
        const check = (target) => 
          target.actions.canDeposit || target.actions.canExchange || (target.splitInfo?.lastCheckOutDate && this.checkDateTime(new Date(target.splitInfo.lastCheckOutDate), new Date()))

        const isActive = 
          check(element) || check(element.firstHalf) || check(element.latterHalf)
        data.isActive = isActive;
      });
      this.arrayMaxDateByYear.forEach((el) => {
        if (el.isActive) {
          this.ArrYear.push(el.year);
        } else {
          this.ArrYearexpired.push(el.year);
        }
      });
    },
    checkDateTime(a, b) {
      if (a - b >= 0) {
        return true;
      } else {
        return false;
      }
    },
    getData(TypeName) {
      this.mwContractUsage = this.getmwContractUsageList.mwContractUsage;
      this.mwPwWeekNumber = this.mwContractUsage.mwPwWeekNumber;
      this.mwRank = this.mwContractUsage.mwRank;
      this.name = this.mwContractUsage.mwPwRoomType.facility.name;
      this.totalYears = this.mwContractUsage.years;
      if (this.mwContractUsage.rights[0]) {
        this.startYear = this.mwContractUsage.rights[0].year;
      }
      this.rights = this.mwContractUsage.rights;
      this.firstHalf = [];
      for (let index = 0; index < this.rights.length; index++) {
        let maxDateLastHalf = null;
        const item = this.rights[index];
        this.year = item.year;
        const ojbListMaxDateByYear = {
          year: item.year,
          maxDate: null,
          listDateDepositBy: null,
        };
        if (TypeName === 'firstHalf') {
          this.dataByType = item.firstHalf;
        } else {
          this.dataByType = item.latterHalf;
        }
        this.firstHalfStatus =
          this.dataByType.status === '予約'
            ? '予約済み'
            : this.dataByType.status;
        if (this.dataByType.splitInfo) {
          this.firstCheckInDate = this.dataByType.splitInfo.firstCheckInDate;
          this.lastCheckOutDate = this.dataByType.splitInfo.lastCheckOutDate;
        } else {
          this.firstCheckInDate = null;
          this.lastCheckOutDate = null;
        }
        // actions by typeName
        const canSplitfirstHalf = this.dataByType.actions.canSplit;
        const canDepositfirstHalf = this.dataByType.actions.canDeposit;
        const canExchangefirstHalf = this.dataByType.actions.canExchange;
        const canRevokeForRCIfirstHalf = this.dataByType.actions.canRevokeForRCI;
        this.depositExpireDatefirstHalf = this.dataByType.actions
          .depositExpireDate
          ? this.dataByType.actions.depositExpireDate.replace(/-/g, '/')
          : 'なし';
        this.depositByfirstHalf = this.dataByType.actions.depositBy
          ? this.dataByType.actions.depositBy.replace(/-/g, '/')
          : 'なし';
        this.firstHalf = this.dataByType.rights;
        if (this.firstCheckInDate && this.lastCheckOutDate) {
          this.NumberOfNights = calculateStayDays(this.firstCheckInDate, this.lastCheckOutDate)
        } else {
          this.NumberOfNights = 0;
        }

        // actions rights
        const canSplit = item.actions.canSplit;
        const canDeposit = item.actions.canDeposit;
        const canExchange = item.actions.canExchange;
        const canRevokeForRCI = item.actions.canRevokeForRCI;
        this.depositExpireDate = item.actions.depositExpireDate
          ? item.actions.depositExpireDate.replace(/-/g, '/')
          : 'なし';
        this.depositBy = item.actions.depositBy
          ? item.actions.depositBy.replace(/-/g, '/')
          : 'なし';
        // muti rights
        for (let i = 0; i < this.firstHalf.length; i++) {
          const element = this.firstHalf[i];
          const elFacilityname = element.roomType.facility.name;
          const roomTypeName = element.roomType.name;
          this.exchangedAt = element.exchangedAt;
          this.depositAt = element.depositAt;
          const bookings = element.newBookingGroup.bookings;
          this.createdAt = element.newBookingGroup.createdAt
            ? element.newBookingGroup.createdAt.split('T')[0]
            : null;
          for (let i = 0; i < bookings.length; i++) {
            const booking = bookings[i];
            let countNight = 0;
            const { checkInDate, checkOutDate } = booking
            if (checkInDate && checkOutDate) {
              countNight = calculateStayDays(checkInDate, checkOutDate);
              if (
                TypeName === 'latterHalf' &&
                (booking.computed.status === '利確待ち' ||
                  booking.computed.status === '利確済み')
              ) {
                if (maxDateLastHalf === null) {
                  maxDateLastHalf = checkOutDate;
                } else {
                  if (
                    this.checkDateTime(
                      new Date(checkOutDate),
                      new Date(maxDateLastHalf),
                    ) === true
                  ) {
                    maxDateLastHalf = checkOutDate;
                  }
                }
              }
            }
            this.mw_item = {
              newBookingGroupID: element.newBookingGroupId,
              mwstatus: element.status,
              days: element.days,
              year: this.year,
              name: elFacilityname,
              roomTypeName,
              bookingsID: booking.id,
              computedStatus: booking.computed.status,
              firstCheckInDate: booking.checkInDate
                ? booking.checkInDate.replace(/-/g, '/')
                : null,
              lastCheckOutDate: booking.checkOutDate
                ? booking.checkOutDate.replace(/-/g, '/')
                : null,
              NumberOfNights: countNight,
              CashConversionAmount: this.CashConversionAmount,
              exchangedAt: this.exchangedAt
                ? this.exchangedAt.replace(/-/g, '/').split('T')[0]
                : null,
              depositAt: this.depositAt
                ? this.depositAt.replace(/-/g, '/').split('T')[0]
                : null,
              createdAt: this.createdAt
                ? this.createdAt.replace(/-/g, '/')
                : null,
              mwPwWeekNumber: this.mwPwWeekNumber,
              mwRank: this.mwRank,
              stayConfirmedAt: booking.stayConfirmedAt,
              canSplit,
              canDeposit,
              canExchange,
              canRevokeForRCI,
              depositExpireDate: this.depositExpireDate
                ? this.depositExpireDate.replace(/-/g, '/')
                : null,
              depositBy: this.depositBy
                ? this.depositBy.replace(/-/g, '/')
                : null,
              canSplitfirstHalf,
              canDepositfirstHalf,
              canExchangefirstHalf,
              canRevokeForRCIfirstHalf,
              depositExpireDatefirstHalf: this.depositExpireDatefirstHalf
                ? this.depositExpireDatefirstHalf.replace(/-/g, '/')
                : null,
              depositByfirstHalf: this.depositByfirstHalf
                ? this.depositByfirstHalf.replace(/-/g, '/')
                : null,
              firstHalfStatus: this.firstHalfStatus,
            };
            if (TypeName === 'firstHalf') {
              this.facilitiesMWContract.push(this.mw_item);
            } else {
              this.facilitiesMWContractlatterHalf.push(this.mw_item);
            }
          }
        }
        if (TypeName === 'latterHalf') {
          ojbListMaxDateByYear.maxDate = maxDateLastHalf;
          this.arrayMaxDateByYear.push(ojbListMaxDateByYear);
        }
      }
    },
    ChangeUseUnconfirmed(item, type) {
      if (type === 0) {
        this.isUdo = false;
        this.selectedYes = 1;
      } else {
        this.isUdo = true;
        this.selectedYes = 2;
      }
      this.statusSelect = true;
      this.bookingId = item;
      this.dialog = 1;
    },
    changeSelected(val) {
      this.statusSelect = false;
      this.dialog = -1;
      const data = {
        id: this.bookingId,
        undo: this.isUdo,
      };
      this.UpdatepwContractUsageList(data).then(() => {
        // this.setAlertSuccess('アクティビティのスケジュールを正常に編集する')
        this.setDialogSuccess(true);
      });
    },
    getLinkPMS(id, newBookingGroupID) {
      const url = `${LINK_PMS.linkContractDetail}?id=${id}&tab=basic-information&fromPage=crm`;
      window.open(url);
    },
    getDataSplitMWByCanSplit(year, type, open) {
      this.indexCom = this.indexCom + 1;
      switch (type) {
        case 0:
          type = 'All';
          break;
        case 1:
          type = 'firstHalf';
          break;
        case 2:
          type = 'latterHalf';
          break;
        default:
          break;
      }
      this.setYearSelected(year);
      this.setTypeSelected(type);
      if (open) {
        this.dialog = 2;
        this.statusShowAll = 1;
      }
    },
    startRevokeForRCI (year, firstHalf, latterHalf) {
      this.$confirm({
        title: 'RCI償却',
        message: '選択された権利をRCI償却します。よろしいですか？（この処理は取り消せません）',
        ok: async () => {
          await this.$store.dispatch('revokeMwRightForRCI', {
            contractId: this.contractID,
            year,
            firstHalf,
            latterHalf
          })
          await this.reload()
        },
      })
    }
  },
  components: {
    contractDetail,
    ContractDialog,
    MWReservationSplit,
    DepositMwBookingDialog,
  },
};
</script>

<style lang="scss" scoped>
.mw-custom-text-b {
  text-align: left;
  font: normal normal medium 14px/19px Noto Sans;
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
}
.custom_table_2 {
  font: normal normal bold 46px/62px Noto Sans;
  letter-spacing: 0px;
  position: absolute;
  left: 40%;
  top: 40%;
  color: #ffffff;
  opacity: 1;
}
.div-custom-mw {
  background: #707070;
  border: 1px solid #707070;
  opacity: 1;
}
.mw-custom-text-top {
  font: normal normal normal 14px/19px Noto Sans;
  letter-spacing: 0px;
  color: #424242;
  opacity: 1;
}
.mw-custom-text {
  font: normal normal 600 10px/13px Noto Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.mw-custom-btn {
  background: #aaaaaa 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}
.span-custom-pw {
  height: 10px;
}
.pw-custom {
  width: 99px;
  height: 27px;
  background: #ffc3b1 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  text-align: center;
  color: #ffffff;
}
.PMS-btn-Cancel {
  width: 176px;
  height: 32px;
  background: #db3069 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
}
.table-primary::v-deep tbody > tr:hover {
  background: #ffffff !important;
}
.text--title {
  font-size: 16px;
  color: #424242;
}
.text-title-component {
  font-size: 20px;
  color: #004f8b;
  font-weight: bold;
}
.text-title-span {
  font-size: 28px;
  color: #004f8b;
  font-weight: 500;
}
.text-center {
  .v-btn--icon {
    background-color: #3083dc !important;
  }
}
.t-btn--prm,
.t-btn--red-dark {
  font-size: 14px !important;
  height: 32px !important;
}
::v-deep {
  .v-card {
    .v-btn:not(.v-btn--round).v-size--small.mw-btn-two {
      background: #13ace0 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
    }
    .v-btn:not(.v-btn--round).v-size--small {
      height: 32px !important;
      background: #aaaaaa 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
    }
  }
  .btn-crm-primary {
    .v-btn__content {
      font-size: 12px !important;
      color: #1873d9;
    }
  }
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 14px !important;
    color: #757575 !important;
    font-weight: bold;
    white-space: nowrap;
    padding: 0 0px !important;
  }
  th::first-letter {
    padding-left: 5px;
  }
  .v-data-table-header {
    .text-start .sortable {
      display: flex !important;
      justify-content: space-between;
      span {
        width: 50%;
      }
    }
  }
  .v-icon__svg {
    color: #000;
  }

  tbody {
    .style-border-none > td:first-child {
      border: none !important;
    }
    .style-border-none > td:nth-child(2) {
      border: none !important;
    }
    .style-border-none > td:nth-child(3) {
      border: none !important;
    }
    .style-border-none > td:nth-child(4) {
      border: none !important;
    }
    .style-border-none > td:nth-child(12) {
      border: none !important;
    }
    .style-border-none > td:nth-child(13) {
      border: none !important;
    }
    .style-border-none > td:nth-child(14) {
      border: none !important;
    }
    .style-border-none > td:nth-child(15) {
      border: none !important;
    }
    .style-border-btm-none > td:first-child {
      border-bottom: none !important;
    }
    .style-border-btm-none > td:nth-child(2) {
      border-bottom: none !important;
    }
    .style-border-btm-none > td:nth-child(3) {
      border-bottom: none !important;
    }
    .style-border-btm-none > td:nth-child(4) {
      border-bottom: none !important;
    }
    .style-border-btm-none:last-child {
      border-bottom: thin solid rgb(0 0 0 / 12%) !important;
    }
    .style-border-btm-none > td:nth-child(12) {
      border: none !important;
    }
    .style-border-btm-none > td:nth-child(13) {
      border: none !important;
    }
    .style-border-btm-none > td:nth-child(14) {
      border: none !important;
    }
    .style-border-btm-none > td:nth-child(15) {
      border: none !important;
    }
    tr {
      td {
        color: #757575 !important;
        font-size: 16px !important;
        white-space: nowrap;
        padding: 0 0px !important;
      }
      td::first-letter {
        padding-left: 5px;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-data-footer__pagination {
    display: none !important;
  }
}
.size-component {
  width: 100%;
}

.btn-add {
  min-width: 92px;
  height: 24px;
  color: #1873d9 !important;
  font-size: 12px;
  border: #1873d9 1px solid !important;
}

.text-diff-part {
  color: #3083dc;
}

.custom-dialog {
  label {
    width: 120px !important;
    flex-shrink: 0;
    text-align: right;
  }
}
.w-75 {
  width: 75%;
}
.general-info {
  position: relative;
}
.title {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #004f8b;
  opacity: 1;
}
</style>
