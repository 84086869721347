<template>
    <div
      class="d-flex justify-space-between pt-3 pb-5 pr-5"
      :class="className"
    >
      <div class="d-flex">
        <div class="mr-6">
          <label class="text--xsmall">{{ firstLabel }}</label>
          <h4 class="mt-1">{{ firstDate && formatDate(firstDate) }}</h4>
        </div>
        <div>
          <label class="text--xsmall">{{ secondLabel }}</label>
          <h4 class="mt-1">{{ secondDate && formatDate(secondDate) }}</h4>
        </div>
      </div>
      <v-btn outlined color="var(--button_red-dark)" class="align-self-end" @click="$emit('handleClick')">
        {{ buttonText }}
      </v-btn>
    </div>
</template>

<script>
import { formatDate } from '@/constants/functions';

  export default {
    name: 'DepositItem',
    props: {
      className: String,
      firstLabel: String,
      secondLabel: String,
      firstDate: String,
      secondDate: String,
      buttonText: String,
    },
    methods: {
      formatDate
    }
  }
</script>

<style lang="scss" scoped>
.text--xsmall{
  color: #212121 !important;
  font-weight: 600 !important;
}
h4{
  color: #212121 !important;
  font-weight: 600;
  font-size: 14px;
}
</style>